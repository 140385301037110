@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior: none;
  background: #151515;
  color: #a0a0a0;
  font-family: 'Space Grotesk', sans-serif;
}

h1 {
  font-size: 30vw;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: 0.7em;
  margin: 0;
  padding: 0;
}

a {
  pointer-events: all;
  color: #a0a0a0;
  text-decoration: none;
}

/* Styles initiaux */
a {
  position: absolute;
  bottom: 40px;
  left: 40px;
  font-size: 13px;
  transition: color 0.3s ease; /* transition douce pour la couleur */
}

/* Effet au survol */
a:hover {
  color: lightgray;
}


svg {
  fill: #a0a0a0;
}
